// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-overview-js": () => import("./../../../src/pages/company-overview.js" /* webpackChunkName: "component---src-pages-company-overview-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-detail-js": () => import("./../../../src/pages/detail.js" /* webpackChunkName: "component---src-pages-detail-js" */),
  "component---src-pages-hp-create-js": () => import("./../../../src/pages/hp-create.js" /* webpackChunkName: "component---src-pages-hp-create-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-list-js": () => import("./../../../src/pages/service-list.js" /* webpackChunkName: "component---src-pages-service-list-js" */)
}

